import React, { useEffect, useState } from 'react';

import '../../assets/css/Valuation.css'

import { Button, Form, Grid, Segment, Divider, Dimmer, Loader, Checkbox } from 'semantic-ui-react'
import { property_api_rents_url, property_api_get_neighborhood_sales_url, property_api_annual_tax_url, property_api_get_neighborhood_loans_url, parcel_search_url } from '../../utility/Config';
import { getFredPrices, makeGetRequest } from '../../utility/Services';
import { runComps, bucketColor } from '../Comps';
import { getChartMovingAverage } from '../charts/ChartHelper';
import { getSalesForNeighborhood } from '../Neighborhoods';
import { onlyUnique, property, flatten, isEmpty, calculatePayment, calculatePmt2Loan, roundToNearest, getParcelAddressQueryParams } from '../../utility/Helper';
import { CompTypeOption, CompBucketOption, CLOSING_COST_PERCENTAGE, SOFT_COST_PERCENTAGE, BROKER_FEES_PERCENTAGE, SALES_COST_PERCENTAGE, PURCHASE_COST_PERCENTAGE } from '../../utility/Global';
import SaleChart from '../charts/SaleChart';
import LTDSChart, { getTDSChartData } from '../charts/LTDSChart';

import rent_figures from '../../assets/json/rent_figures.json'
import section_eight_figures from '../../assets/json/section_eight_figures.json'
import { FormattedInputType, MoneyFormattedInput, NumberFormattedInput, PercentFormattedInput } from '../extras/InputElements';
import { ValueContainer } from '../extras/ValueContainer';
import MapboxMap from '../maps/MapboxMap';
import BucketCalc from '../../BucketCalc';
import SearchLocationInput from '../extras/SearchLocationInput';
import Lot from '../../Tax-Lot';

rent_figures.sort((a, b) => a.value < b.value ? 1 : -1)

const LoadState = {
  NONE: 0,
  SALES: 1,
  FINANCING: 2,  
  FINISHING: 3,
  FINISHED: 4
}

function getRentForRooms(rooms) {
  const rates = section_eight_figures.filter(_ => _.bedrooms === rooms)
  if (rates.length === 0)
    return 0
  return rates[0].value
}

function getRentalRateUnitModifier(unit) {
  switch (unit) {
    case 1:
      return -.2
    case 2:
      return -.1
    case 3:
      return .1
    case 4:
      return .2
    default:
      return 0
  }
}

function getRentalRate(value, unit) {
  let modifier = getRentalRateUnitModifier(unit)
  let rate = 0
  rent_figures.every(element => {
    if (value >= element.value) {
      rate = element.rate
      return false
    }
    return true
  })

  return ((value * rate) / 12) * (1 + modifier)
}

const PropertyCondition = {
  Poor: 1,
  Fair: 2,
  Excellent: 3
}

const propertyConditionOptions = [
  { key: PropertyCondition.Excellent, text: 'Excellent', value: PropertyCondition.Excellent },
  { key: PropertyCondition.Fair, text: 'Fair', value: PropertyCondition.Fair },
  { key: PropertyCondition.Poor, text: 'Poor', value: PropertyCondition.Poor },
]

function getChartAvgSaleLine(lowX, highX, yValue, color) {
  let data = {
    color: color,
    data: [
      { x: lowX, y: yValue ?? 0 },
      { x: highX, y: yValue ?? 0 }
    ]
  }
  return data
}

const CompFormField = ({ label, type = FormattedInputType.MONEY, value, onChange, onBlur, disabled }) => (
  <Form.Field className='comp-inline-field comp-form-field' inline>
    <label>{label}</label>
    {
      type === FormattedInputType.MONEY ? <MoneyFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
        type === FormattedInputType.PERCENT ? <PercentFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
          type === FormattedInputType.NUMBER ? <NumberFormattedInput defaultValue={value} onChange={onChange} onBlur={onBlur} disabled={disabled} /> :
            null
    }
  </Form.Field>
)

const RateFormField = ({ value, onChange, isIO, onChangeIO }) => (
  <Form.Field className='comp-inline-field comp-form-field' inline>
    <label>Rate &nbsp; &nbsp;
      <Checkbox label='I/O?' checked={isIO} onChange={onChangeIO} />
    </label>
    <PercentFormattedInput defaultValue={value} onChange={onChange} />
  </Form.Field>
)


let salesCompsData = null
let financingCompsData = null

const Valuation = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadState, setLoadState] = useState(LoadState.NONE)
  const [value, setValue] = useState('')

  const [target, setTarget] = useState(null)
  const [targetSqft, setTargetSqft] = useState(0)
  const [valuationData, setValuationData] = useState(null)

  const [ltv, setLtv] = useState(75)
  const [minDSCR, setMinDSCR] = useState(1.25)
  const [condition, setCondition] = useState(PropertyCondition.Excellent)
  const [interestRate, setInterestRate] = useState(0)

  const [entryPrice, setEntryPrice] = useState(0)
  const [exitPrice, setExitPrice] = useState(0)
  const [financingCap, setFinancingCap] = useState(0)
  const [totalRent, setTotalRent] = useState(0)
  const [insurance, setInsurance] = useState(0)
  const [annualTax, setAnnualTax] = useState(0)

  const [finalLTV, setFinalLTV] = useState(0)
  const [dscr, setDSCR] = useState(0)

  const [isIO, setIsIO] = useState(false)

  const [profitMarginPercent, setProfitMarginPercent] = useState(50)
  const [maxEntryPrice, setMaxEntryPrice] = useState(0)
  const [minExitPrice, setMinExitPrice] = useState(0)
  const [dscrMaxLoanAmount, setDSCRMaxLoanAmount] = useState(0)

  const [fredIndex, setFredIndex] = useState(null)
  const [constructionCost, setConstructionCost] = useState(0)
  const [constructionCostEstimate, setConstructionCostEstimate] = useState(0)

  const [salesChart, setSalesChart] = useState(null)
  const [loansChart, setLoansChart] = useState(null)

  const [salesTDSChart, setSalesTDSChart] = useState(null)
  const [financingTDSChart, setFinancingTDSChart] = useState(null)

  const [markerData, setMarkerData] = useState(null)

  const insuranceModifier = 1

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getFredPrices().then(prices => {
        const {observations} = prices
        if (observations && observations.length > 0)
          setFredIndex(observations[0].value)
      })
    }
    return () => mounted = false
  }, [])


  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) calculateMinExitAndMaxEntryPrices()
  }, [profitMarginPercent, constructionCostEstimate, entryPrice, exitPrice])

  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) {
      calculateTotalRent()
      calculateConstructionCosts()
    }
  }, [condition])

  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) calculateDSCR()
  }, [ltv, minDSCR, interestRate, financingCap, exitPrice, entryPrice, annualTax, insurance, totalRent, isIO])

  useEffect(() => {
    if (target && loadState === LoadState.FINISHED) setConstructionCostEstimate(constructionCost * targetSqft)
  }, [constructionCost, targetSqft])

  useEffect(() => {
    if (target && salesCompsData && financingCompsData) {
      if (targetSqft !== target.bldgArea) {
        target.bldgArea = targetSqft
        target.pricePerSqft = target.documentAmount / targetSqft

        financingCompsData.comps[0].bldgArea = targetSqft
        financingCompsData.comps[0].pricePerSqft = target.documentAmount / targetSqft

        setMarkerData(null)

        // TODO: REPLACE
        //finishSalesComps(calculateExtendedComps(salesCompsData.comps, target, { type: CompTypeOption.DEED }, salesCompsData.neighborhoodAverages))
        //finishFinancingComps(calculateExtendedComps(financingCompsData.comps, target, { type: CompTypeOption.MORTGAGE }, financingCompsData.neighborhoodAverages))
      }
    }
  }, [targetSqft])

  useEffect(() => {
    if (target !== null && !target.label) {
      async function fetchData() {
        var rentalData = {}
        if (Lot.isPluto(target)) {
          rentalData = await makeGetRequest(`${property_api_rents_url}?bbl=${target.propertyId}`)

          const annualTax = await makeGetRequest(`${property_api_annual_tax_url}?bbl=${target.propertyId}`)
          setAnnualTax(annualTax.annualTax)
        } else {
          if (target.bedrooms) {
            rentalData.bedrooms = [target.bedrooms]
          }
          setAnnualTax(target.annualTax)
        }

        if (rentalData) {
          const totalBedrooms = rentalData.bedrooms != null && rentalData.bedrooms.length > 0 ? rentalData.bedrooms.reduce((a, b) => a + b) : 0
          const totalRent = rentalData.rents != null && rentalData.rents.length > 0 ? rentalData.rents.reduce((a, b) => a + b) : 0

          rentalData.totalBedrooms = totalBedrooms
          rentalData.totalRent = totalRent

          let seTotalRent = 0
          if (rentalData.bedrooms) {
            rentalData.bedrooms.forEach(rooms => {
              seTotalRent += getRentForRooms(rooms - 1 >= 0 ? rooms - 1 : rooms)
            }) 
          }

          rentalData.seTotalRent = seTotalRent
        }

        setInterestRate((Math.ceil((2.917 + 3) * 8)) / 8)

        setTargetSqft(target.bldgArea)
        setInsurance(target.bldgArea * insuranceModifier)
        
        setValuationData(valuationData => (
          {
            ...valuationData,
            rentalData,
            getFinancing: true
          }
        ))
      }
      fetchData()
    }
  }, [target])

  function exitCosts(exit, lot) {
    if (Lot.isPluto(lot)) {
      return (exit * SALES_COST_PERCENTAGE) + (exit * BROKER_FEES_PERCENTAGE)
    }
    return (exit * .06); // combined fees
  }

  function entryCosts(entry, lot) {
    if (Lot.isPluto(lot)) {
      return (entry * PURCHASE_COST_PERCENTAGE) + (entry * SOFT_COST_PERCENTAGE)
    }
    return (entry * .05);// combined fees
  }

  function totalInvestment(entry, exit, construction, lot) {
    return entry + construction + exitCosts(exit, lot) + entryCosts(entry, lot)
  }

  const calculateMinExitAndMaxEntryPrices = () => {
    const min = ((entryPrice + constructionCostEstimate) * (1 + (profitMarginPercent / 100)))
    let max = (((exitPrice / (1 + (profitMarginPercent / 100))) - constructionCostEstimate) - exitCosts(exitPrice))

    const expenses = max * CLOSING_COST_PERCENTAGE
    max = max - expenses

    //const targetEntry = exitPrice

    setMaxEntryPrice(max)
    setMinExitPrice(min)
  }

  const calculateTotalRent = () => {
    const { rentalData, middleCalc } = valuationData

    let tmpTotalRent = 0
    switch (condition) {
      case PropertyCondition.Poor:
        tmpTotalRent = getRentalRate(entryPrice, target.unitCode)
        break
      case PropertyCondition.Fair:
        tmpTotalRent = getRentalRate(BucketCalc.selectedPriceToUse(middleCalc), target.unitCode)
        break
      case PropertyCondition.Excellent:
        tmpTotalRent = getRentalRate(exitPrice, target.unitCode)
        break
      default:
        break
    }

    const { seTotalRent } = rentalData || {}
    const rentToUse = seTotalRent >= tmpTotalRent ? seTotalRent : tmpTotalRent

    setTotalRent(roundToNearest(rentToUse))
  }

  const calculateConstructionCosts = () => {
    const modifier = Math.min(exitPrice/2000000, 1)
    //const fredHigh = (valuationData?.exitPSF * .25)

    let tmpConstructionCosts = 0
    switch (condition) {
      case PropertyCondition.Poor:
        //tmpConstructionCosts = fredHigh > (fredIndex / 2) ? (fredIndex / 2) + (valuationData?.exitPSF * .05) : (fredIndex / 2)
        tmpConstructionCosts = (fredIndex * .625) * modifier 
        break
      case PropertyCondition.Fair:
        //tmpConstructionCosts = fredHigh > (fredIndex / 2) ? (fredIndex / 2) + (valuationData?.exitPSF * .025) : (fredIndex / 2)
        //tmpConstructionCosts /= 2
        tmpConstructionCosts = (fredIndex * .375) * modifier 
        break
      default:
        break
    }

    setConstructionCost(roundToNearest(tmpConstructionCosts))

    let estimates = tmpConstructionCosts * targetSqft

    setValuationData(valuationData => ({
      ...valuationData,
      constructionCostEstimate: estimates,
      profitAtExit: (valuationData.exitPrice && ((valuationData.exitPrice - valuationData.entryPrice - estimates) / (valuationData.entryPrice + estimates)) * 100)
    }))
  }

  const calculateDSCR = () => {
    const { middleCalc } = valuationData

    const payment = isIO ? ((financingCap * (interestRate / 100)) / 12) : calculatePayment(financingCap, (interestRate / 100))
    const piti = payment + (annualTax / 12) + (insurance / 12)

    let ltvValue = 0
    let tmpPrice = 0
    switch (condition) {
      case PropertyCondition.Poor:
        // Take Low Bucket Price * LTV
        tmpPrice = entryPrice
        break
      case PropertyCondition.Fair:
        // Take Middle Bucket Price * LTV
        tmpPrice = BucketCalc.selectedPriceToUse(middleCalc)
        break
      case PropertyCondition.Excellent:
        // Take High Bucket Price * LTV
        tmpPrice = exitPrice
        break
      default:
        break
    }
    ltvValue = tmpPrice * (ltv / 100)

    const dscr = totalRent / piti
    // console.log('PAYMENT ', payment)
    // console.log('PITI ', piti)
    // console.log('TOTAL RENT ', totalRent)
    // console.log('DSCR ', dscr)

    let dscrMaxLoanAmount = financingCap
    // console.log('MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    if (dscr < minDSCR) {
      // console.log('DSCR LOWER THAN MIN DSCR')
      const pmt = (totalRent / minDSCR) - (annualTax / 12) - (insurance / 12)
      dscrMaxLoanAmount = isIO ? ((pmt * 12) / (interestRate / 100)) : calculatePmt2Loan(pmt, (interestRate / 100))
      // console.log('NEW MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    }

    if (dscrMaxLoanAmount > ltvValue) {
      // console.log('MAX LOAN AMOUNT GREATER THAN LTV VALUE')
      dscrMaxLoanAmount = ltvValue
      // console.log('NEW MAX LOAN AMOUNT ', dscrMaxLoanAmount)
    }

    const dscrPayment = isIO ? ((dscrMaxLoanAmount * (interestRate / 100)) / 12) : calculatePayment(dscrMaxLoanAmount, (interestRate / 100))
    const dscrPiti = dscrPayment + (annualTax / 12) + (insurance / 12)
    // console.log('DSCR PAYMENT', dscrPayment)
    // console.log('DSCR PITI', dscrPiti)

    setFinalLTV((dscrMaxLoanAmount / tmpPrice) * 100)
    setDSCR(totalRent / dscrPiti)
    setDSCRMaxLoanAmount(dscrMaxLoanAmount)

    setValuationData(valuationData => ({
      ...valuationData,
      piti,
      payment
    }))
  }

  const onClickBack = () => {
    setValue('')
    setTarget(null)
    setTargetSqft(0)
    setConstructionCost(0)
    setValuationData(null)

    setSalesChart(null)
    setLoansChart(null)
    setSalesTDSChart(null)
    setFinancingTDSChart(null)

    setEntryPrice(0)
    setExitPrice(0)
    setFinancingCap(0)
    setTotalRent(0)
    setInsurance(0)
    setAnnualTax(0)

    setFinalLTV(0)
    setDSCR(0)

    setIsIO(false)

    setMaxEntryPrice(0)
    setMinExitPrice(0)
    setDSCRMaxLoanAmount(0)

    setMarkerData(null)

    setLoadState(LoadState.NONE)
    salesCompsData = null
    financingCompsData = null
  }

  
  const handleSearchChange = async (written, address, parts) => {
    //console.log(address, parts)

    if (address.length === 0) return

    setIsLoading(true)

    var search = getParcelAddressQueryParams(written, address, parts)

    let result = await getAddress(search)
    
    setPropertyToRun(result)
    return {success: true, isPluto: Lot.isPluto(result)}
};

const getAddress = async (search) => {
  console.log(search)
  let result = await makeGetRequest(`${parcel_search_url}?${search}`)

  setIsLoading(false)

  if (!result) {
      alert('No Address Found')
      return
  }
      
  return result
  }

  const setPropertyToRun = (result) => {
    setTarget(result)
    setLoadState(LoadState.SALES)
  }

  useEffect(() => {
    switch (loadState) {
        case LoadState.SALES:
            runComps(target,
                {
                    type: CompTypeOption.DEED,
                    ds: target.type
                }, (compResults, canceled) => {
                    if (!canceled) {
                        finishSalesComps(compResults)
                    }
                })
            break
        case LoadState.FINANCING:
            runComps(target,
                {
                    type: CompTypeOption.MORTGAGE,
                    ds: target.type
                },
                (compResults, canceled) => {
                    if (!canceled) {
                        finishFinancingComps(compResults)
                    }
                })
            break
        case LoadState.FINISHING:
            {
              calculateTotalRent()
              calculateConstructionCosts()
              calculateMinExitAndMaxEntryPrices()
      
              setLoadState(LoadState.FINISHED)
            }
            break
        default:
          break
    }
}, [loadState])

  const finishSalesComps = (compResults) => {
    const { compsData } = compResults || {}
    if (compsData) {
      salesCompsData = compsData

      const { data, buckets, composyteBuckets, comps, chartData } = compsData || {}
      let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]

      let highest = composyteBuckets.find(b => b.title === "Highest")
      let entry = buckets.lowBucket && BucketCalc.selectedPriceToUse(buckets.lowBucket)
      let exit = highest && BucketCalc.selectedPriceToUse(highest)

      setValuationData(valuationData => ({
        ...valuationData,
        entryPrice: entry,
        exitPrice: exit,
        exitPSF: highest && BucketCalc.selectedPsfToUse(highest),
        middleCalc: buckets?.middleBucket,
        totalDistance: data?.totalDistance
      }))

      setEntryPrice(entry)
      setExitPrice(exit)

      setTarget(foundTarget)
      setValue(Lot.fullAddress(target))

      setMarkerData(comps.filter(_ => _.bucket && _.bucket.length > 0 && _.bucket !== CompBucketOption.SUBJECT && _.bucket !== CompBucketOption.REMOVED))

      if (Lot.isPluto(foundTarget)) {
        fetchSales(foundTarget, buckets)
      }
      setSalesTDSChart(setupLTDSChart(comps, chartData))

      setLoadState(LoadState.FINANCING)
    }
  }

  const finishFinancingComps = (compResults) => {
    const { compsData } = compResults || {}
    if (compsData) {
      const { buckets, comps, chartData } = compsData || {}

      financingCompsData = compsData
      let foundTarget = comps.filter(_ => _.propertyId.toString() === target.propertyId.toString())[0]
  
      setValuationData(valuationData => ({
        ...valuationData,
        financingMiddleCalc: buckets?.middleBucket,
        getFinancing: false,
        finishCalculating: true
      }))

      setFinancingCap(buckets && buckets.middleBucket && BucketCalc.selectedPriceToUse(buckets.middleBucket))

      if (Lot.isPluto(foundTarget)) {
        fetchSales(foundTarget, buckets, false)
      }
      setFinancingTDSChart(setupLTDSChart(comps, chartData))

      setLoadState(LoadState.FINISHING)
    }
  }

  const setupLTDSChart = (comps, stdInfo) => {
    let data = comps.map(_ => {
      let color = ''
      if (_.bucket && _.bucket.length > 0) {
        color = bucketColor(_.bucket)
      } else {
        color = '#ececec'
      }
      return getTDSChartData(_, color)
    })

    data.sort((a, b) => a.x > b.x ? 1 : -1)

    let movingAverage = getChartMovingAverage(data, 0, 100, 10)

    //console.log(movingAverage)
    return { data: data, stdInfo, movingAverage }
  }

  const fetchSales = async (target, calc, isSales = true) => {
    const { neighborhood, borocode, bldgArea, residentialUnits } = target
    //const { lowBucketCalc, middleLowBucketCalc, middleBucketCalc, middleHighBucketCalc, highBucketCalc } = calc
    const { lowBucket, middleBucket, highBucket } = calc

    const url = isSales ? property_api_get_neighborhood_sales_url : property_api_get_neighborhood_loans_url

    const result = await makeGetRequest(`${url}?neighborhood=${neighborhood}&borocode=${borocode}`)
    if (result) {
      const sales = JSON.parse(result.chart)
      const saleData = sales.map(_ => _.sale)

      let years = saleData.map(property("years")).reduce(flatten, []).filter(onlyUnique)

      years.sort()

      let saleTrends = []
      let unitSaleTrends = []
      let movingAverage = []
      let unitMovingAverage = []

      let t = { ...getSalesForNeighborhood(saleData, neighborhood, borocode) }
      if (t && t.data) {
        years.reverse().forEach(year => {
          let sale = {}
          let yearlyData = t.data.filter(_ => _.year === year /*&& _.units === target.unitsres*/)
          sale.data = yearlyData.sort((a, b) => (a.y > b.y) ? -1 : 1)
          sale.neighborhood = t.neighborhood
          sale.year = year

          saleTrends.push(sale)

          let unitSale = {}
          let unitYearlyData = t.data.filter(_ => _.year === year && _.units === target.residentialUnits)
          unitSale.data = unitYearlyData.sort((a, b) => (a.y > b.y) ? -1 : 1)
          unitSale.neighborhood = t.neighborhood
          unitSale.year = year

          unitSaleTrends.push(unitSale)
        })
      }

      let lowX = bldgArea * .9
      let highX = bldgArea * 1.1

      let weightedAverages = []

      if (!isEmpty(lowBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(lowBucket), bucketColor(CompBucketOption.LOW)))
      }

      // if (!isEmpty(middleLowBucketCalc)) {
      //   weightedAverages.push(getChartAvgSaleLine(lowX, highX, middleLowBucketCalc?.avgPSF, bucketColor(CompBucketOption.MIDDLELOW)))
      // }

      if (!isEmpty(middleBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(middleBucket), bucketColor(CompBucketOption.MIDDLE)))
      }

      // if (!isEmpty(middleHighBucketCalc)) {
      //   weightedAverages.push(getChartAvgSaleLine(lowX, highX, middleHighBucketCalc?.avgPSF, bucketColor(CompBucketOption.MIDDLEHIGH)))
      // }

      if (!isEmpty(highBucket)) {
        weightedAverages.push(getChartAvgSaleLine(lowX, highX, BucketCalc.selectedPsfToUse(highBucket), bucketColor(CompBucketOption.HIGH)))
      }


      if (saleTrends.length > 0) {
        let allData = saleTrends.map(property("data")).reduce(flatten, []).sort((a, b) => (a.x > b.x) ? 1 : -1)

        movingAverage = getChartMovingAverage(allData)

        let unitData = allData.filter(_ => _.units === residentialUnits)

        unitMovingAverage = getChartMovingAverage(unitData)
      }

      if (isSales) {
        setSalesChart({ saleTrends, unitSaleTrends, movingAverage, unitMovingAverage, weightedAverages: weightedAverages })
      } else {
        setLoansChart({ saleTrends, unitSaleTrends, movingAverage, unitMovingAverage, weightedAverages: weightedAverages })
      }
    }
  }

  return (
    <div className='resp-container'>
      <Segment basic style={{ minHeight: '100vh', background: '#f8f8f8', padding: '4em 2em 2em' }}>
        <Form size='small'>
          <Form.Group>
            <Form.Field className='comp-inline-field' inline>
              <label>Subject Property</label>
              <SearchLocationInput 
                  className="fluid" 
                  name=''
                  label=''
                  icon='home'
                  value={value}
                  placeholder='Enter property address'
                  onChange={handleSearchChange} 
                  isLoading={isLoading} />
            </Form.Field>
            <Form.Select
              options={propertyConditionOptions}
              placeholder='Select Condition'
              value={condition}
              onChange={(e, { value }) => setCondition(value)}
            />
            <Form.Field><Button icon='x' onClick={onClickBack}></Button></Form.Field>
          </Form.Group>
        </Form>
        <Divider />
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <Segment.Group>
                <ValueContainer top='DSCR' label='Max Loan Amount' value={roundToNearest(dscrMaxLoanAmount)} />
                <Segment.Group className='values-container' horizontal style={{ background: 'white' }}>
                  <ValueContainer label='LTV' value={finalLTV} type={FormattedInputType.PERCENT} />
                  <ValueContainer label='DSCR' value={dscr} type={FormattedInputType.NONE} />
                </Segment.Group>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column width={13}>
              <Segment.Group>
                <Segment.Group className='flip-flip-values-container' horizontal style={{ background: 'white' }}>
                  <ValueContainer top=' &nbsp; ' label='Entry Price' value={roundToNearest(valuationData?.entryPrice ?? 0)} />
                  <ValueContainer top=' &nbsp; ' label='Entry Costs' value={roundToNearest(entryCosts(valuationData?.entryPrice ?? 0, target))} />
                  <ValueContainer top=' &nbsp; ' label='Const. Estimate' value={roundToNearest(valuationData?.constructionCostEstimate ?? 0)} />
                  <ValueContainer top='F & F Market' label='Exit Costs' value={roundToNearest(exitCosts(valuationData?.exitPrice ?? 0, target))} />
                  <ValueContainer top=' &nbsp; ' label='Total Investment' value={roundToNearest(target ? totalInvestment(valuationData?.entryPrice, valuationData?.exitPrice, valuationData?.constructionCostEstimate) : 0)} />
                  <ValueContainer top=' &nbsp; ' label='Exit Price' value={roundToNearest(valuationData?.exitPrice ?? 0)} />
                  <ValueContainer top=' &nbsp; ' label='Profit at Exit' value={valuationData?.profitAtExit ?? 0} type={FormattedInputType.PERCENT} color={valuationData?.profitAtExit < profitMarginPercent ? 'red' : ''} />
                </Segment.Group>
                <Segment.Group className='flip-flip-values-container' horizontal style={{ background: 'white' }}>
                  <ValueContainer top=' &nbsp; ' label='Max Entry Price' value={roundToNearest(maxEntryPrice)} color={maxEntryPrice < entryPrice ? 'red' : ''} />
                  <ValueContainer top=' &nbsp; ' label='Entry Costs' value={roundToNearest(entryCosts(maxEntryPrice, target))} />
                  <ValueContainer top=' &nbsp; ' label='Const. Budget' value={roundToNearest(constructionCostEstimate)} />
                  <ValueContainer top='F & F Calculated' label='Exit Costs' value={roundToNearest(exitCosts(exitPrice, target))} />
                  <ValueContainer top=' &nbsp; ' label='Total Investment' value={roundToNearest(target ? totalInvestment(maxEntryPrice, exitPrice, constructionCostEstimate, target) : 0)} />
                  <ValueContainer top=' &nbsp; ' label='Min Exit Price' value={roundToNearest(minExitPrice)} color={minExitPrice > exitPrice ? 'red' : ''} />
                  <ValueContainer top=' &nbsp; ' label='Profit at Exit' value={target && profitMarginPercent ? profitMarginPercent : 0} type={FormattedInputType.PERCENT} />
                </Segment.Group>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid textAlign='center' columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <CompFormField label='Entry Price' value={entryPrice} onChange={(e, value) => setEntryPrice(Number(value))} />
                <CompFormField label='Exit Price' value={exitPrice} onChange={(e, value) => setExitPrice(Number(value))} />
                <CompFormField label='Financing Cap' value={financingCap} onChange={(e, value) => setFinancingCap(Number(value))} disabled={true} />
                <CompFormField label='Rents/Mo' value={totalRent} onChange={(e, value) => setTotalRent(Number(value))} />
                <CompFormField label='Property Sqft' type={FormattedInputType.NUMBER} value={targetSqft} onBlur={(e, value) => setTargetSqft(Number(value))} />
                <CompFormField label='Const. Cost' value={constructionCost} onChange={(e, value) => setConstructionCost(Number(value))} />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <CompFormField label='LTV' type={FormattedInputType.PERCENT} value={ltv} onChange={(e, value) => setLtv(Number(value))} />
                <RateFormField value={interestRate} onChange={(e, value) => setInterestRate(Number(value))} isIO={isIO} onChangeIO={(e, target) => setIsIO(target.checked)} />
                <CompFormField label='Min DSCR' type={FormattedInputType.NUMBER} value={minDSCR} onChange={(e, value) => setMinDSCR(Number(value))} />
                <CompFormField label='Profit Margin %' type={FormattedInputType.PERCENT} value={profitMarginPercent} onChange={(e, value) => setProfitMarginPercent(Number(value))} />
                <CompFormField label='Insurance/Yr' value={insurance} onChange={(e, value) => setInsurance(Number(value))} />
                <CompFormField label='Taxes/Yr' value={annualTax} onChange={(e, value) => setAnnualTax(Number(value))} />
              </Form>
            </Grid.Column>
            <Grid.Column>
              <MapboxMap
                className='valuation-map-container'
                target={target}
                markerData={markerData}
                totalDistance={valuationData?.totalDistance || 1}
                popupPosition='left'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Segment.Group style={{ height: '50vh', flex: 'auto', margin: 0 }} stacked horizontal>
          {salesTDSChart && <Segment basic className='popup-tds-chart-container'>
            <LTDSChart
              chartData={salesTDSChart}
              cWidth={600}
            />
          </Segment>

          }

          {salesChart && <Segment basic className='popup-sale-chart-container'>
            <SaleChart
              chartData={salesChart.unitSaleTrends}
              //movingAverage={salesChart.movingAverage} 
              movingAverage={null}
              unitMovingAverage={salesChart.unitMovingAverage}
              weightedAverages={salesChart.weightedAverages}
              scatterSize={1}
              showLegend={false}
              brushArea={target.bldgArea}
              sameColor={true} />
          </Segment>
          }
        </Segment.Group>
        <Segment.Group style={{ height: '50vh', flex: 'auto' }} stacked horizontal>

          {financingTDSChart && <Segment basic className='popup-tds-chart-container'>
            <LTDSChart
              chartData={financingTDSChart}
              cWidth={600}
            />
          </Segment>

          }

          {loansChart && <Segment basic className='popup-sale-chart-container'>
            <SaleChart
              chartData={loansChart.unitSaleTrends}
              //movingAverage={salesChart.movingAverage} 
              movingAverage={null}
              unitMovingAverage={loansChart.unitMovingAverage}
              weightedAverages={loansChart.weightedAverages}
              scatterSize={1}
              showLegend={false}
              brushArea={target.bldgArea}
              sameColor={true} />
          </Segment>
          }
        </Segment.Group>
        <Dimmer page active={loadState > LoadState.NONE && loadState < LoadState.FINISHED}>
          <Loader />
        </Dimmer>
      </Segment>
    </div>
  )
}

export default Valuation


